// popup layer when user leaves from Education to other networks
.leaveORG_bg {
	padding: 7px 7px 0 7px;
	border-radius: 0;
	background: $white;
}

.leaveORG_text {
	text-align:center;
}

.leaveorg_links {
	padding:10px 10px;
	width:146px;
	margin:10px;
	display:inline-block;
	cursor:pointer;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	background: $mscp-blue;
	color: $white;
	font-weight: normal;
	border: 1px solid $mscp-blue;
}

.leaveorg_links#org_stay_btn {
	color: $mscp-blue;
	background: $white;
}

.leaveorg_links:hover, 
.leaveorg_links#org_stay_btn:hover {
	background: $hover-blue;
	color: $white;
}

/*logout layer*/
.layer-whiteout {
	width:100%;
	height:100%;
	background: $white;
	visibility:hidden;
	opacity:0;
	position:absolute;
	z-index:$z-index_layer + 496;
	margin:0 auto;
	height:auto;
	top:40px;
	right:0;
	bottom:0;
	left:0;
	visibility: hidden;
	-moz-opacity: 0.80;
	filter: alpha(opacity=80);
	opacity: 0;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}

.layer-pop {
	visibility:hidden;
	opacity:0;
	font-family: $font-light;
	font-size:1.55em;
	position:absolute;
	display:block !important;
	background-color: $white;
	padding:30px 0 40px;
	margin:30px auto 0;
	width:278px;
	z-index: $z-index_menu + 505;
	left:50%;
	margin-left: -139px;
	-webkit-box-shadow: 0 1px 1px 4px rgba(0,0,0,.05);
	box-shadow: 0 1px 1px 4px rgba(0,0,0,.05);
	border: 1px solid rgba(0,0,0,.1);
	text-align:center;
	box-sizing:border-box;

	b {
		font-family: $font-semi;
	}
}

.layer-pop-header {
	background: $hover-blue;
	color:white;
	font-size: 1.26em;
	padding: 15px 20px;
	position:fixed;
	box-sizing:border-box;
}

.layer-pop .layer-content {
	margin:20px 30px 0;
}

.layer-pop .layer-content-group {
	overflow:hidden;
}

/* UPDATED Text Layer Ads 10/2011 */
/* layer ad 1 */
#layerAd1 {
	padding: 0 5px 22px 5px;
	background-color: $white;
	border: 1px solid #909090;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0 2px 5px 4px #ababab;
	-moz-box-shadow: 0 2px 5px 4px #ababab;
	box-shadow: 0 2px 5px 4px #ababab;
	top: 220px;
	left: 35%;
	width: 440px;
	z-index: $z-index_layer + 400;
	position: fixed;
	font-family: arial, sans-serif;
	font-size: 76%;
}

#layerAd1 .adClose {
	margin: 0;
	padding: 0;
	font-size: 0.95em;
	position: relative;
	top: -25px;
	right: -8px;
	text-align: right;
}

#layerAd1 .adClose a {
	margin: 0;
	padding: 3px 8px;
	color: #000;
	text-decoration: none;
	background-color: #e9e9e9;
	background: -moz-linear-gradient(top, #ffffff 0%, #e9e9e9 25%, #d0d0d0 50%, #ededed 75%, #ffffff);
	background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(0.25, #e9e9e9), color-stop(0.50, #d0d0d0), color-stop(0.75, #ededed), to(#ffffff));
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border: 1px solid #7c7c7c;
	-moz-box-shadow: 0px 1px 2px rgba(000, 000, 000, 0.5), inset 0px 1px 2px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0px 1px 2px rgba(000, 000, 000, 0.5), inset 0px 1px 2px rgba(255, 255, 255, 1);
	text-shadow: 0px 0px 0px rgba(000, 000, 000, 0), 0px 2px 1px rgba(255, 255, 255, 1);
}

#layerAd1 .adContent {
	padding: 40px 0 6px 0;
	border: 1px solid #909090;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	background: #9dbfd9 url("/pi/global/ornaments/bg-layer-ad-1.jpg") top left no-repeat;
}

#layerAd1 .adContent .adTitle {
	padding: 0 20px 20px 20px;
	font-weight: bold;
	font-size: 1.8em;
	color: #002356;
}

#layerAd1 .adContent .adText {
	padding: 0 20px 20px 20px;
	font-size: 1.4em;
}

#layerAd1 .adContent .adTextAlt {
	padding: 0 20px 20px 20px;
	color: #ff6600;
	font-weight: bold;
	font-size: 1.4em;
}

#layerAd1 .adContent .cta {
	margin: 10px 0 30px 0;
	font-weight: bold;
	font-size: 1.2em;
	text-align: center;
}

#layerAd1 .adContent .cta a {
	padding: 6px 18px 6px 18px;
	color: #133178;
	text-decoration: none;
	text-decoration: underline\9;
	background: -moz-linear-gradient(top, #e9e9e9 0%, #d0d0d0);
	background: -webkit-gradient(linear, left top, left bottom, from(#e9e9e9), to(#d0d0d0));
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border: 1px solid #7c7c7c;
	border: none\9;
	-moz-box-shadow: 0 1px 2px rgba(000, 000, 000, 0.5), inset 0 1px 2px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0 1px 2px rgba(000, 000, 000, 0.5), inset 0 1px 2px rgba(255, 255, 255, 1);
	text-shadow: 0px 0px 0px rgba(000, 000, 000, 0), 0px 2px 1px rgba(255, 255, 255, 1);
}

#layerAd1 .adContent .adType {
	margin: 0;
	padding: 0;
	color: #666;
	font-size: 0.95em;
	position: relative;
	top: 25px;
	left: 2px;
}


/* layer ad 2 */
#layerAd2 {
	padding: 0px 5px 5px;
	background-color: #105078;
	border: 1px solid #105078;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0 2px 5px 4px #ababab;
	-moz-box-shadow: 0 2px 5px 4px #ababab;
	box-shadow: 0 2px 5px 4px #ababab;
	top: 275px;
	left: 35%;
	width: 440px;
	z-index: $z-index_layer + 450;
	position: fixed;
	font-family: arial, sans-serif;
	font-size: 76%;
}

#layerAd2 .adClose {
	margin: 0;
	padding: 0;
	font-size: 0.95em;
	position: relative;
	top: -25px;
	right: -8px;
	text-align: right;
}

#layerAd2 .adClose a {
	margin: 0;
	padding: 3px 8px;
	color: #000;
	text-decoration: none;
	background-color: #e9e9e9;
	background: -moz-linear-gradient(top, #ffffff 0%, #e9e9e9 25%, #d0d0d0 50%, #ededed 75%, #ffffff);
	background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(0.25, #e9e9e9), color-stop(0.5, #d0d0d0), color-stop(0.75, #ededed), to(#ffffff));
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border: 1px solid #7c7c7c;
	-moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5), inset 0px 1px 2px white;
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5), inset 0px 1px 2px white;
	text-shadow: 0px 0px 0px transparent, 0px 2px 1px white;
}

#layerAd2 .adContent {
	padding: 40px 0 6px 0;
	border: 1px solid #909090;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	background-color: $white;
	background: -webkit-gradient(linear, left bottom, left top, color-stop(0, white), color-stop(0.68, #e9e9e9));
	background: -moz-linear-gradient(center bottom, white 0%, #e9e9e9 68%);
	cursor: pointer;
	cursor: hand;
}

#layerAd2 .adContent .adTitle {
	padding: 0 20px 20px 20px;
	font-weight: bold;
	font-size: 1.8em;
	color: #002356;
}

#layerAd2 .adContent .adTitle a {
	color: #000000;
	text-decoration: none;
}

#layerAd2 .adContent .adText {
	padding: 0 20px 20px 20px;
	font-size: 1.4em;
}

#layerAd2 .adContent .adTextAlt {
	padding: 0 20px 20px 20px;
	color: #ff6600;
	font-weight: bold;
	font-size: 1.4em;
}

#layerAd2 .adContent .cta {
	margin: 10px 0 30px 0;
	font-weight: bold;
	font-size: 1.2em;
	text-align: center;
}

#layerAd2 .adContent .cta a {
	padding: 6px 18px 6px 18px;
	color: #133178;
	text-decoration: none;
	background: -moz-linear-gradient(top, #e9e9e9 0%, #d0d0d0);
	background: -webkit-gradient(linear, left top, left bottom, from(#e9e9e9), to(#d0d0d0));
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border: 1px solid #7c7c7c;
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 1px 2px white;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 1px 2px white;
	text-shadow: 0px 0px 0px transparent, 0px 2px 1px white;
}

#layerAd2 .adContent .adType {
	margin: 0;
	padding: 0;
	color: $white;
	font-size: 0.95em;
	position: relative;
	top: 25px;
	left: 2px;
}

#layerAd2 .adContent .layerJC {
	text-align: right;
	font-size: .75em;
	padding-right: 5px;
}

#layerAd2 .adType {
	color: $white;
	font-size: 1.05em;
	text-align: right;
	height: 15px;
	top: 13px;
}
/* End Layer Ad 2 */


/* layer ad 3 */

#layerAd3.inactive{
    display:none;
}

#layerAd3 .starts-in{
    padding-bottom: 5px;
}
#layerAd3 sub {
  vertical-align: text-bottom;
}
#layerAd3 .image{
    position: relative;
}
#layerAd3 .image img{
    height: 50px;
}

#layerAd3 #counter{
    margin-top: -41px;
    font-size: 31px;
    font-weight: 700;
}

#layerAd3 .image{
    margin-bottom: 20px;
}

#layerAd3 .desktop-breakpoint .adType{
    font-size: 14px;
}

#layerAd3 .mobile-layerAd3Wrap .adType{
    font-size: 14px;
}

#layerAd3 .adType{
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #666666;
}

#layerAd3{
    z-index: 4500000;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-reg;
}

#layerAd3Wrap.desktop-breakpoint{
    width: 570px;
}

#layerAd3Wrap.mobile-countdown{
    width: 300px;
}


#layerAd3 .desktop-breakpoint .adTitle{
    display:block;
    font-size: 24px;
    padding: 10px 20px;
}

#layerAd3 .mobile-countdown .adTitle{
    font-size: 18px;
    padding: 20px 10px;
}

#layerAd3Wrap .adTitle{
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
    text-decoration: none;
    display: inline-block;
}

#layerAd3 .desktop-breakpoint .adText{
    font-size: 20px;
}

#layerAd3 .mobile-countdown .adText{
    font-size: 16px;
}

#layerAd3Wrap .adText{
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
    padding-bottom: 20px;
    margin: 0px;
}

#layerAd3Wrap{
    display: block;
    margin: 0 auto;
    min-height: 200px;
    background: $white;
    -moz-box-shadow: 0px 4px 10px 0px rgba(000,000,000,0.4);
    -webkit-box-shadow: 0px 4px 10px 0px rgba(000,000,000,0.4);
    box-shadow: 0px 4px 10px 0px rgba(000,000,000,0.4);
    color: #000;
    padding-bottom: 30px;
}

#layerAd3Wrap .layer-ad-header {
    border-bottom: 1px solid #d8d8d8;
    padding: 20px 20px 30px 20px;
}

#layerAd3 .header-logo-layer img{
    float: left;
    height: 16px;
}

#layerAd3Wrap #close {
    margin: 0px 0 0 0;
    cursor: pointer;
    float: right;
    font-size: 14px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: #1b88bf;
    text-decoration: none;
}

#layerAd3Wrap .adContent {
    padding: 30px 34px 0 34px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}


#layerAd3 .blue-button{
    color: $white;
    background: $mscp-blue;
}

#layerAd3Wrap .blue-button:hover {
    background: $hover-blue;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

#layerAd3 .white-button{
    background: $white;
    color: $mscp-blue;
}
#layerAd3Wrap .white-button:hover {
    border: 1px solid $mscp-blue;
}

#layerAd3Wrap .btn {
    padding: 10px 24px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #99cbe0;
    cursor: pointer;
    margin: 0 auto;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-family: $font-reg;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.4px;
}

#layerAd3 .cta{
    text-align: center;
}

#layerAd3 .jcContent {
    margin-top: 15px;
    text-align: right;
    font-size: 11px;
    line-height: 1.45;
    color: #666666;
    padding-right: 15px;
    font-family: "ArialMT", Arial, Helvetica, sans-serif;
}

#layerAd3 .jcContent .prescInfo {
    padding-right: 10px;
}

#layerAd3 .references{
    text-align: left;
    padding-left: 15px;
    padding-bottom: 15px
}


a:active.prescInfo,a.prescInfo,a:visited.prescInfo,a:link.prescInfo {
    color: #666666;
    text-decoration: none !important;
}

a:hover.prescInfo{
    color: $mscp-blue;
    text-decoration: none !important;
}

/* End ad 3 */


/* Update Text Layer Ads on News and Ref tempaltes 10/2011 */
/*Global Headline display update*/

.ifibjc {
	margin: 0;
	padding: 0;
	color: #797979;
	font-size: 0.65em;
	text-align: left;
}
/* idetail iframe classes */

#idetailwinbg {
	text-align: center;
	border-style: ridge;
	border-color: #b3b3b3;
	border-width: 7px;
	background-color: $white;
}

#layerbtns {
	text-align: right;
	padding: 12px 12px 12px 12px; }

#layerbtns #closepres {
	float: right;
}

#layerbtns #mscp-logo {
	float:left;
	height: 15px;
}


/*************************************
	Invites layer styles
*************************************/

#invites-layer .row {
    margin: 0 auto;
    padding: 10px 10px 25px !important;
    background-color: transparent;
    border-bottom: 0;
}

@media (min-width: 1024px) {

  /* container */
	.invwarningscroller {
		margin: 2px 0 2px 0;
		padding: 3px;
		font-size: 0.95em;
		height: 55px;
		border: 1px solid #b3b3b3;
		overflow: auto;
		background-color: $white;

		.invwarningscroller strong {
			font-size: 1.0em;
		}
	}

	.honorarium {
		font-size: 0.95em;
		color: #ff6600;
	}

	.invites-layeritemeven {
		background-color: #EEF5FD;
		border: 1px solid $white;
		font-size: 0.95em;
		margin: 0;
		padding: 12px 8px;
	}

	.invites-layeritemodd {
		background-color: #E9E9E9;
		border: 1px solid $white;
		font-size: 0.95em;
		margin: 0;
		padding: 12px 8px;
	}

	.invwarningscroller table {
		background-color: $white;
	}

	.invwarningscroller table td {
		background-color: $white;
	}

	#invites-layer-main .burst {
		float: left;
	}

	// New Invitations Design [Mehdi 11-23-2016 PPE-76529 & PPE-79730]
	#invites-layer {
		width: 310px;
		height: 96%;
		margin: 0 auto;
		background-color: #f7f7f7;
		border: none;
		border-radius: 1.5px;
		box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
	}

	#invites-top-right {
		position: relative;
		bottom: 14px;
		right: 5px;
		width: 44px;
		height: 17px;
		font-family: $font-reg;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #1b88bf;
		float: right;
	}

	#invites-top-left {
		position: relative;
		top: 14px;
		width: 180px;
		height: 36px;
		font-family: $font-bold;
		font-size: 18px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1;
		color: $title-gray;
		text-align: left;
	}

	#invites-layer-buttons {
		position: relative;
		left: 10px;
		height: 0;
	}

	.invites-layer-button {
		position: relative;
		top: -20px;
		float: right;
		right: 5px;
		width: 95px;
		height: 32px;
		border-radius: 4px;
		background-color: $hover-blue;
		background-image: none;
	}

	.invites-layer-button-text {
        padding-top: 9px;
  	}

	.invites-layer-button-text a:link {
		width: 100%;
		height: 16px;
		font-family: $font-bold;
		font-size: 13px;
		font-style: normal;
		font-stretch: normal;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		color: $white;
		margin-left: 0;
	}

	.invites-layer-button-text a:hover {
		color: $white;
		text-decoration: none;
	}

	.invites-layer-button-text a:visited {
		color: $white;
		text-decoration: none;
	}

	#invites-top-left .you-have-txt {
		font-size: 16px;
		font-weight: normal;
		line-height: 1.13;
	}

	#invites-top-left a {
		text-decoration: none;
		display: inline-block;
		width: 100%;
		margin-left: 0;
	}

	#invites-top-left a:hover {
		color: $title-gray;
		text-decoration: none;
	}

	#invites-top-left a:visited {
		color: $title-gray;
		text-decoration: none;
	}

	#invites-top-left a:link {
		color: $title-gray;
		text-decoration: none;
	}

	#invites-layer-main {
		position: relative;
		top: 0px;
		width: 95%;
		height: 80%;
		border: none;
		overflow: hidden;
		overflow-y: auto;
		margin-top: 15px;
		margin-left: 7px;
	}

	#info-industry {
		width: 160px;
		height: 13px;
		text-align: left;
		font-family: $font-reg;
		font-size: 11px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #666;
		float: none;
		margin: 0;
	}

	.invite-gift, 
	.invites-layerreward {
		color: #FF6600;
		font-size: .9em;
		font-weight: bold;
		padding: 5px 0 5px 0;
	}

	.invite-item-box {
		text-align: left;
		margin: 0 auto;
		background-color: $white;
		border: solid 1px #e0e4e7;
		border-top: solid 4px #e0e4e7;
		margin-bottom: 10px;
		padding: 12px;
	}

	.user-links .invites-item-title, #invites-layer-main .invites-item-title {
		width: 100%;
		height: 24px;
		font-family: $font-rb;
		font-size: 20px !important;
		font-weight: normal;
		font-style: normal;
		line-height: 1.25;
		margin-left: 0;
	}

	.user-links .invite-description a, #invites-layer-main .invite-description a {
		font-weight: bold !important;
		color: #1b88bf !important;
		margin-left: 0;
	}

	.invite-description {
		width: 245px; 
		height: auto;
		font-family: $font-reg;
		font-size: 16px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
	}

	.top-arrow:after,
	.top-arrow:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.top-arrow:after {
		border-color: #f7f7f7;
		border-bottom-color: transparent;
		border-right-color: transparent;
		border-width: 19px;
		left: 50%;
		margin-left: -19px;
		transform: rotate(45deg);
		box-shadow: -6px -6px 10px -6px rgba(0, 0, 0, 0.5);
		top: -12px;
	}

	.top-arrow:before {
		border-color: transparent;
		border-bottom-color: #e3e6e9;
		border-width: 20px;
		left: 50%;
		margin-left: -20px;
	}

	.new-invitation-title {
		display: block;
		width: 151px;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #94bb00;
		padding-bottom: 10px;
	}

	.new-invitation-border {
		border-top: solid 4px #94bb00;
	}

	.expiring-title {
		width: 100%;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #d1000c;
		padding-bottom: 10px;
	}

	.expiring-border {
		border-top: solid 4px #d1000c;
	}

	.recommended-title {
		width: 151px;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #767674;
		padding-bottom: 10px;
	}

	.close-invites {
		cursor: pointer; 
	}

	.show-invites {
		display: block !important;
	}

	.hide-invites {
		display: none !important;
	}

	.no_invites {
		text-align: left;
		width: 88%;
		margin: 0 auto;
		margin-top: 20px;
		font-family: $font-rb;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
	}
}

@include breakpoint(2) {

	// New Invitations Design [Mehdi 11-23-2016 PPE-76529 & PPE-79730]
	#invites-layer {
		width: 90%;
		height: 100%;
		overflow-y: auto;
		margin: auto;
		background-color: #f7f7f7;
		border: none;
		border-radius: 1.5px;
		box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
	}

	#invites-layer .row {
		width: 95%;
		margin: 0 auto;
	}

	#invites-layer-main {
		position: relative;
		top: 20px;
		width: 95%;
		margin: 0 auto;
		padding-top: 20px;
		border: none;
	}

	#invitations-list {
		width: 95%;
		margin: 0 auto;
	}

	#info-industry {
		position: relative;
		top: 20px;
		width: 160px;
		height: 13px;
		font-family: $font-reg;
		font-size: 11px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #666;
		float: none;
		margin: 0;
		z-index: $z-index_layer + 1;
	}

	#invites-top-right {
		position: relative;
		top: 4px;
		right: 0;
		width: 44px;
		height: 17px;
		font-family: $font-bold;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #1b88bf;
		float: right;
	}

	#invites-top-left {
		position: relative;
		top: 23px;
		width: 180px;
		height: 36px;
		font-family: $font-bold;
		font-size: 18px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1;
		color: $title-gray;
		margin-top: 15px;
	}

	#invites-layer-buttons {
		position: relative;
		top: 19px;
		height: 0;
		left: 45px;
		float: right;
	}

	.invites-layer-button {
		position: relative;
		float: right;
		top: -10px;
		width: 95px;
		height: 32px;
		border-radius: 4px;
		background-color: $hover-blue;
		background-image:none;
	}

	.invites-layer-button-text a:link {
		width: 100%;
		height: 16px;
		font-family: $font-bold;
		font-size: 13px;
		font-style: normal;
		font-stretch: normal;
		color: $white;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px 0;
		color: $white;
	}

	.invites-layer-button-text a:hover {
		color: $white;
		text-decoration: none;
	}

	.invites-layer-button-text a:visited {
		color: $white;
		text-decoration: none;
	}

	#invites-top-left .you-have-txt {
		font-size: 16px;
		font-weight: normal;
		line-height: 1.13;
	}

	#invites-top-left a {
		text-decoration: none;
		display: inline-block;
		width: 100%;
	}

	#invites-top-left a:hover {
		color: $title-gray;
		text-decoration: none;
	}

	#invites-top-left a:visited {
		color: $title-gray;
		text-decoration: none;
	}

	#invites-top-left a:link {
		color: $title-gray;
		text-decoration: none;
	}

	.invite-item-box {
		width: initial;
		background-color: $white;
		border: solid 1px #e0e4e7;
		border-top: solid 4px #e0e4e7;
		margin-bottom: 10px;
		padding: 12px;
	}

	.invites-item-title {
		width: 251px;
		height: 24px;
		font-family: $font-rb;
		font-size: 20px !important;
		font-weight: normal;
		font-style: normal;
		color: #000 !important;
		text-decoration: none;
	}

	.invite_description {
		width: 251px;
		height: auto;
		font-family: $font-reg;
		font-size: 16px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
	}

	.invite-description a {
		font-weight: bold !important;
		color: #1b88bf !important;
	}

	.invite-gift, 
	.invites-layerreward {
		padding: 5px 0 5px 0;
	}

	.user-links a {
		margin: 0;
	}

	.top-arrow-mobile:after,
	.top-arrow-mobile:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.top-arrow-mobile:after {
		border-width: 15px;
		left: 7.7%;
		transform: rotate(45deg);
		box-shadow: 0px -5px 7px -7px rgba(0, 0, 0, 0.5);
		top: -15px;
		border-top: 15px solid #f7f7f7;
	}

	.top-arrow-mobile:before {
		border-width: 15px;
		left: 7.7%;
		transform: rotate(45deg);
		top: -15px;
		border-top: 15px solid #f7f7f7;
	}

	.new-invitation-title {
		display: block;
		width: 151px;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #94bb00;
		padding-bottom: 10px;
	}

	.new-invitation-border {
		border-top: solid 4px #94bb00;
	}

	.recommended-title {
		width: 151px;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #767674;
		padding-bottom: 10px;
	}

	.expiring-title {
		width: 100%;
		height: 14px;
		font-family: Arial;
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		font-stretch: normal;
		line-height: 1;
		color: #d1000c;
		padding-bottom: 10px;
	}

	.expiring-border {
		border-top: solid 4px #d1000c;
	}

	.lock-touchscreen{
		height: 100%;
		width: 100%;
		overflow: hidden;
		position: fixed;
	}

	.close-invites {
    	cursor: pointer;
    }

	.show-invites {
		display: block !important;
	}

	.hide-invites {
		display: none !important;
	}

	.no_invites {
    	text-align: left;
    	width: 88%;
    	margin: 0 auto;
    	margin-top: 20px;
    	font-family: $font-rb;
    	font-size: 16px;
    	font-weight: normal;
    	font-style: normal;
    }

	.bucket-header h2 {
    	width: 100%;
    	height: initial;
    	margin: 0 auto;
    	font-family: $font-reg;
    	font-size: 18px;
    	font-weight: normal;
    	font-style: normal;
    	font-stretch: normal;
    	color: $title-gray;
    	border-bottom: 1px solid $title-gray;
	}

	.dr-name {
    	font-weight: bold;
	}

	.info-tag {
    	text-transform: initial;
    	height: 13px;
    	font-family: $font-reg;
    	font-size: 11px;
    	font-weight: normal;
    	font-style: normal;
    	font-stretch: normal;
    	color: #666;
	}

	#invitesbody {
		width: initial;
	}
}
/* /Invites layer */

/* safe use alert */
.safeUseAlert {
	margin: 0 0 15px 0;
	padding: 5px 6px 5px 6px;
	font-size: 0.95em;
	background-color: #fffeee;
	border: 1px solid #cbcbcb;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
}

.safeUseAlert ul, .safeUseAlert li {
	margin: 0;
	padding: 0;
	list-style: none;
}

#rightcol .safeUseAlert ul, 
#rightcol .safeUseAlert li {
	margin: 0;
	padding: 0;
	border: none;
	-moz-border-radius: none;
	-webkit-border-radius: none;
	border-radius: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.safeUseAlert .alertTag {
	padding-right: 2px;
	text-transform: uppercase;
	font-size: 0.9em;
	font-weight: bold;
	color: #fe733c;
}
/* safe use alert alternate */

.safeUseAlert.alt {
	margin: 0 0 20px 0;
	padding: 7px 10px 9px 10px;
	font-size: .95em;
	background-color: $white;
	border: 1px solid #aa0000;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	-moz-box-shadow: 0px 2px 3px rgba(000, 000, 000, 0.3), inset 0px 0px 7px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 3px rgba(000, 000, 000, 0.3), inset 0px 0px 7px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 2px 3px rgba(000, 000, 000, 0.3), inset 0px 0px 7px rgba(0, 0, 0, 0.3);
}

.safeUseAlert.alt a {
	color: #aa0000;
}

.safeUseAlert.alt .alertTag {
	padding-right: 2px;
	text-transform: uppercase;
	font-size: .9em;
	font-weight: bold;
	color: #aa0000;
}

/* Layer Ad Classes */

#medlayerad {
	/* original version */
	margin: 0;
	padding: 0;
	position: fixed;
	z-index: $z-index_layer + 450;
	top: 220px;
	width: 525px;
	margin-left: -262px;
	left: 50%;
}

#medlayeradbox {
	width: 525px;
	margin: 0;
	padding: 0;
	border-top: 2px solid #777;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;
	border-left: 2px solid #777;
	background-color: #fff6d4;
}

.medlayeradcontent {
	margin: 8px;
	padding: 10px 15px 10px 15px;
	font-family: arial, sans-serif;
	font-size: 1.0em;
	background-color: $white;
	border: 1px solid #ffcc66;
}

.medlayeradaction {
	text-align: center;
}

.medlayeradclosebutton {
	text-align: right;
	font-size: 1.0em;
	margin: 0;
	padding: 0 0 5px 0;
}

.medlayeradclosebutton a:hover {
	font-weight: normal;
	text-decoration: none;
}

.medlayeradtext {
	margin: 0;
	padding: 3px 6px 8px 6px;
}

.medlayeradtext a:link {
	font-family: verdana, sans-serif;
	color: #ff6600;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtext a:visited {
	font-family: verdana, sans-serif;
	color: #ff6600;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtext a:hover {
	font-family: verdana, sans-serif;
	color: #ff0000;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.20em;
}

.medlayeradtext a:active {
	font-family: verdana, sans-serif;
	color: #ff6600;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextmain {
	font-family: verdana, sans-serif;
	padding: 5px 0 6px 0;
	color: #003366;
	font-size: 1.1em;
	font-weight: bold;
}

.medlayeradtextlink {
	color: $mscp-blue;
}

/* Layer Ad Classes */
#medlayeradboxB {
	width: 525px;
	margin: 0;
	padding: 0;
	border-top: 2px solid #777;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;
	border-left: 2px solid #777;
	background-color: #FF6600;
}

.medlayeradcontentB {
	margin: 4px;
	padding: 10px 15px 10px 15px;
	font-family: arial, sans-serif;
	font-size: 1.0em;
	background-color: #003366;
	border: 1px solid $white;
}

.medlayeradactionB {
	margin-left: 155px;
	padding: 5px 8px 5px 8px;
	width: 125px;
	background-color: #003366;
	text-align: center;
}

.medlayeradtextB {
	margin: 0;
	padding: 3px 6px 8px 6px;
}

.medlayeradtextB a:link {
	font-family: verdana, sans-serif;
	color: #7bddfd;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextB a:visited {
	font-family: verdana, sans-serif;
	color: #7bddfd;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextB a:hover {
	font-family: verdana, sans-serif;
	color: #FF6600;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.20em;
}

.medlayeradtextB a:active {
	font-family: verdana, sans-serif;
	color: #7bddfd;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextmainB {
	font-family: georgia, serif;
	padding: 5px 0 10px 0;
	color: $white;
	font-size: 1.25em;
	font-weight: bold;
}

#medlayeradboxB .infofromlegal {
	text-align: right;
	font-size: 11px;
	color: #969696;
}

#medlayeradboxB .medlayeradclosebutton a {
	color: #7bddfd;
}

#medlayeradboxB .medlayeradclosebutton a:link {
	color: #7bddfd;
}

#medlayeradboxB .medlayeradclosebutton a:visited {
	color: #7bddfd;
}

#medlayeradboxB .medlayeradclosebutton a:hover {
	color: #FF6600;
}

#medlayeradboxB .medlayeradclosebutton a:active {
	color: #7bddfd;
}

#medlayeradboxC {
	width: 525px;
	margin: 0;
	padding: 0;
	border-top: 2px solid #777;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;
	border-left: 2px solid #777;
	background-color: #FDDD7C;
}

.medlayeradcontentC {
	margin: 2px;
	padding: 10px 15px 10px 15px;
	font-family: arial, sans-serif;
	font-size: 1.0em;
	background-color: #7bddfd;
	border: 1px solid $white;
}

.medlayeradactionC {
	margin-left: 135px;
	padding: 5px 8px 5px 8px;
	width: 195px;
	background-color: #7bddfd;
	text-align: center;
}

.medlayeradtextC {
	margin: 0;
	padding: 3px 6px 8px 6px;
}

.medlayeradtextC a:link {
	font-family: verdana, sans-serif;
	color: #fddd7c;
	font-weight: bold;
	font-size: 1.60em;
}

.medlayeradtextC a:visited {
	font-family: verdana, sans-serif;
	color: #fddd7c;
	font-weight: bold;
	font-size: 1.60em;
}

.medlayeradtextC a:hover {
	font-family: verdana, sans-serif;
	color: #003366;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.60em;
}

.medlayeradtextC a:active {
	font-family: verdana, sans-serif;
	color: #fddd7c;
	font-weight: bold;
	font-size: 1.60em;
}

.medlayeradtextmainC {
	font-family: verdana, sans-serif;
	padding: 5px 0 6px 0;
	color: #003366;
	font-size: 1.1em;
	font-weight: bold;
}

#medlayeradboxC .infofromlegal {
	text-align: right;
	font-size: 11px;
	color: #393939;
}

#medlayeradboxC .medlayeradclosebutton a {
	color: #003366;
}

#medlayeradboxC .medlayeradclosebutton a:link {
	color: #003366;
}

#medlayeradboxC .medlayeradclosebutton a:visited {
	color: #003366;
}

#medlayeradboxC .medlayeradclosebutton a:hover {
	color: #fddd7c;
}

#medlayeradboxC .medlayeradclosebutton a:active {
	color: #003366;
}

#medlayeradboxD {
	width: 525px;
	margin: 0;
	padding: 0;
	border-top: 2px solid #777;
	border-right: 2px solid #000;
	border-bottom: 2px solid #000;
	border-left: 2px solid #777;
	background-color: #434343;
}

.medlayeradcontentD {
	margin: 6px;
	padding: 10px 15px 10px 15px;
	font-family: arial, sans-serif;
	font-size: 1.0em;
	background-color: #f6f6f6;
	border: 1px solid #cc0000;
}

.medlayeradactionD {
	margin-left: 155px;
	padding: 5px 8px 5px 8px;
	width: 125px;
	background-color: $white;
	text-align: center;
}

.medlayeradtextD {
	margin: 0;
	padding: 3px 6px 8px 6px;
}

.medlayeradtextD a:link {
	font-family: verdana, sans-serif;
	color: #cc0000;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextD a:visited {
	font-family: verdana, sans-serif;
	color: #cc0000;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextD a:hover {
	font-family: verdana, sans-serif;
	color: #6f6f6f;
	font-weight: bold;
	text-decoration: none;
	font-size: 1.20em;
}

.medlayeradtextD a:active {
	font-family: verdana, sans-serif;
	color: #cc0000;
	font-weight: bold;
	font-size: 1.20em;
}

.medlayeradtextmainD {
	font-family: verdana, sans-serif;
	padding: 5px 0 6px 0;
	color: #00578b;
	font-size: 1.1em;
	font-weight: bold;
}

/* Invalid Email Alert Layer */
#invalidemailalert {
	position: fixed;
	z-index: $z-index_layer + 500;
	left: 50%;
	top: 20%;
	width: 520px;
	padding: 0;
	margin-left: -260px;
}

#invalidemailalert div.c1 {
	-moz-box-shadow: 0.2em 0.2em 0.5em #666;
}

#invalidemailalert div.c2 {
	border: 1px solid #000;
}

#invalidemailalert div.c3 {
	border: 3px solid #006699;
}

#invalidemailalert div.c4 {
	border: 1px solid #000;
	padding: 12px;
	background-color: $white;
	text-align: left;
}

#invalidemailalert h2 {
	color: #003366;
	font-family: verdana, sans-serif;
	font-size: 1.25em;
	margin: 0;
	padding: 0 0 8px 0;
}

#invalidemailalert p {
	margin: 0;
	padding: 0 0 12px 62px;
	background-image: url("/pi/global/ornaments/caution-sign.gif");
	background-repeat: no-repeat;
	background-position: left top;
	color: #606060;
	font-size: 1.00em;
}

#invalidemailalert .formlabel {
	margin: 4px 3px 12px 0;
	font-size: 1.00em;
}

#invalidemailalert .formfield {
	margin: 0 0 12px 0;
	font-size: 1.00em;
}

#invalidemailalert .formfield2 {
	margin: 4px 0 12px 3px;
	font-size: 1.00em;
}

#invalidemailalert .formfield2 a {
	text-decoration: underline;
}

#invalidemailalert #successful {
	text-align: center;
	font-family: Verdana, sans-serif;
	margin: 20px 0 20px 0;
	color: #009933;
	font-size: 1.25em;
	font-weight: bold;
}

#invalidemailalert .closelink {
	text-align: right;
	font-size: 11px;
}

#invalidemailalert ul {
	margin: 0;
	padding: 4px 0 8px 24px;
}

#invalidemailalert li {
	padding: 0 0 4px 0;
}

#invalidemailalert .errortext {
	color: #FF3300;
}

#invalidemailalert div.formfield .errortext {
	font-size: 0.90em;
}
/* /Invalid Email Alert Layer */

/*logout layer*/
#layer-logout-confirmation.showLogout {
	visibility:visible;
	opacity:1;
	top:40px;
}

#layer-logout-bg.showLogout {
	visibility:visible;
	-webkit-opacity:0.8;
	opacity:0.8;
	top:0;
}

.layer-iframe {
	width: 100%;
	top: 0;
	border: 0;
	height: 100%;
}

.layer-pop .required-section {
	padding: 0;
	text-align:center;
}

.layer-pop-button, .btn-blue {
	padding: 10px 10px;
	width: 6.5rem;
	margin: 15px auto;
	display: block;
	cursor: pointer;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	background: $mscp-blue;
	color: $white !important;
	font-weight: normal;
	border: 1px solid $mscp-blue;
	font-size:1rem;
	text-align:center;
}

a.layer-pop-button:link, 
a.layer-pop-button:active, 
a.layer-pop-button:visited {
	color: $white;
}

a.layer-pop-button:hover {
	text-decoration:none;
}

.layer-pop-button.alt, 
a.layer-pop-button.alt:link, 
a.layer-pop-button.alt:active, 
a.layer-pop-button.alt:visited {
	color:$mscp-blue !important;
	background: $white;
}

.updateProfileSuccess #layer-update-confirmation {
	visibility:visible;
	opacity:1;
}

.updateProfileSuccess #layer-update-bg {
	visibility:visible;
	-webkit-opacity:0.8;
	opacity:0.8;
}

.icon-checkmark {
	width:49px;
	height:35px;
	display: block;
	margin:20px auto 0;
	background:url('/pi/global/icons/icon-checkmark-green-49x35.png') no-repeat;
	background-size:49px 35px;
}

/* SGV style */
#nDlayer_plate, #messagelayer{
	font-size: 76%;
	font-family: arial,sans-serif
}

#nDlayer_plate p, #messagelayer p {
	margin: 0 0 12px;
}
/* /SGV style */

#video-violator {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	padding: 0;
	background-color: #ececec;
	z-index: 999;

	&.show {
    	display: block;
	}

	.resp-container {
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 9px 5px;
		position: relative;
		text-align: center;
		font-size: 16px;
		color: #000000;

		.close-video-violator {
			flex: 0 0 auto;
			background: transparent;
			border: none;
			cursor: pointer;
			margin-left: auto;
			padding-left: 10px;

			svg {
				display: inline-block;
				width: 14px;
				height: 14px;
			}
		}
	
		.text-contain {
			margin-left: auto;
			flex: 0 1 auto;
		}

		.medscape-text {
			font-weight: 600;
			font-family: $font-semi;
		}

		.video-text {
			font-size: 14px; 
			vertical-align: bottom;
			font-family: $font-semi;
			text-transform: uppercase;
		}

		.new-label {
			display: inline-block;
			position: relative;
      		top: -5px;
      		font-size: 9px;
      		font-weight: bold;
      		color: #d0021c;
      		vertical-align: middle;
      		text-transform: uppercase;
      		font-family: $font-semi;
      		text-decoration: none;
      	}

		.promo-copy {
			font-weight: 300;
      		color: $title-gray;
      		margin-left: 10px;
		}

		.desktop-text {
      		display: inline;

			@include breakpoint(1) {
				display: none;
			}
		}

		.mobile-text {
      		display: none;

			@include breakpoint(1) {
        		display: inline;
      		}
    	}

		.explore-now-button {
			display: inline-block;
			flex: 0 0 auto;
			border-radius: 3px;
			background-color: #017caf;
			padding: 8px 12px 8px 12px;
			vertical-align: middle;
			font-size: 16px;
			line-height: 1;
			color: $white;
			text-align: center;
			text-decoration: none;
			margin-left: 10px;
			box-sizing: border-box;
			height: 32px;
		}
	}
}

#instructions-overlay #icons > div {
    text-align: left;
}

#instructions-overlay #icons img {
    cursor: pointer;
    margin: 8px;
    height: 48px;
    width: 48px;
    display: inline-block;
}

#instructions-overlay #icons{
    text-align: left;
}

#ad-blocker-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.59);
    z-index: 6000000;
}

#ab-message-overlay {
    position: absolute;
    width: 762px;
    background-color: $white;
    border: 3px;
    left: 50%;
    top: 140px;
    transform: translate(-50%, -0%);
    border: 5px solid $mscp-blue;
    z-index: 6000500;
}

#ab-logo {
    height: 2.5rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

#ab-header {
    margin: 0 auto;
    width: 60%;
    margin-bottom: 25px;
    font-size: 40px;
    color: #4a4a4a;
    text-align: center;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.7px;
    
}

#ab-message-1,
#ab-message-2 {
    text-align: center;
    font-size: 18px;
    line-height: 1.33;
    margin: 0px;
}

#ab-message-2 {
    margin-bottom: 39px;
}

#allow-ads-btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 18px 72px;
    color: $white;
    background: $mscp-blue;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #99cbe0;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    width: 335px;
}

#ab-thank-you {
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #4a4a4a;
    font-weight: 300;
}

#ab-message-overlay #allow-ads-btn:hover {
    background: $hover-blue;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

#ad-blocker-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.59);
    z-index: 6000000;
}

#instructions-overlay {
    position: absolute;
    width: 515px;
    background-color: $white;
    border: 3px;
    left: 50%;
    top: 140px;
    transform: translate(-50%, -0%);
    border: 5px solid $mscp-blue;
    z-index: 6000500;
    display: none;
}

#ab-message-overlay-close, #instructions-overlay-close {
    height: 28px;
    width: 28px;
    background: url("/pi/global/blockmsg/icon-close-ab.svg") no-repeat;
    font-size: 0.9em;
    cursor: pointer;
    margin: 20px 20px 20px auto;
}

#instructions-overlay, 
#ab-message-overlay,
#instructions-gif {
    z-index: 6000300;
}


#instructions-overlay #icons > div {
    text-align: left;
}

#instructions-overlay #icons img {
    cursor: pointer;
    margin: 8px;
    height: 48px;
    width: 48px;
}

#instructions-overlay #icons {
    text-align: left;
}

#instructions-overlay-content {
    padding: 0 20px;
}

#instructions-overlay-content p {
	text-align: left;
	font-size:18px;
	line-height: 1.33;
	margin-top: 18px;
	margin-bottom: 18px;
}

#instructions-two, 
#instructions-three {
    visibility: hidden;
}

#instructions-three {
    margin-bottom: 30px;
}

#instructions-gif {
    position: fixed;
    top: 5px;
    right: 5px;
}

/* begin selective-driver layer */

/* desktop selective driver swap */
.sd-layer-show .sd-layer-background {
    visibility: visible;
}

.sd-content-footer a.sd-pi:active, 
.sd-content-footer a.sd-pi, 
.sd-content-footer a.sd-pi:visited, 
.sd-content-footer a.sd-pi:link {
    color: #666;
    text-decoration: none !important;
}

.selective-driver .sd-content-footer .sd-pi {
    padding-right: 10px;
}

.sd-layer .align-right {
    text-align: right !important;
}

.sd-layer .align-left {
    text-align: left !important;
}

.sd-layer sub {
    vertical-align: text-bottom;
}

@media (min-width:768px) {

    .selective-driver-cdtp .sd-layer .sd-content .starts-in {
        padding-bottom: 5px;
    }

    .selective-driver-cdtp .sd-layer .sd-content .image {
        position: relative;
    }

    .selective-driver-cdtp .sd-layer .sd-content .image img {
        height: 50px;
    }

    .selective-driver-cdtp .sd-layer .sd-content #counter {
        margin-top: -41px;
        font-size: 31px;
        font-weight: 700;
    }

	.selective-driver-cdtp .sd-layer .sd-content .image {
        margin-bottom: 20px;
    }

    .sd-layer .sd-content .sd-type {
        font-size: 14px;
        color: #666;
        font-weight: normal;
        font-style: normal;
    	font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    .sd-layer {
        z-index: 6000496;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: $font-reg;
        width: 570px;
    }

    .sd-layer .sd-content .sd-title {
        display: block;
        font-size: 24px;
        padding: 10px 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #222;
        text-decoration: none;
        display: inline-block;
    }

    .sd-layer .sd-content .sd-info {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #222;
        padding-bottom: 20px;
        margin: 0px;
    }

    .sd-layer {
        display: block;
        margin: 0 auto;
        min-height: 200px;
        background: $white;
        -moz-box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
        -webkit-box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
        box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
        color: #000;
        padding-bottom: 30px;
    }

	.sd-layer .sd-header {
		border-bottom: 1px solid #d8d8d8;
		padding: 20px 20px 30px 20px;
	}

	.sd-layer .sd-header .logo img {
		float: left;
		height: 16px;
	}

	.sd-layer .sd-header .close {
		margin: 0px 0 0 0;
		cursor: pointer;
		float: right;
		font-size: 14px !important;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #1b88bf;
		text-decoration: none;
	}

	.sd-layer .sd-content {
		padding: 30px 34px 0 34px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
	}

	.sd-layer .sd-content .btn-link-blue {
		color: $white;
		background: $mscp-blue;
	}

	.sd-layer .sd-content .btn-link-blue:hover {
		background: $hover-blue;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}

	.sd-layer .sd-content .btn-link-white {
		background: $white;
		color: $mscp-blue;
	}

	.sd-layer .sd-content .btn-link-white:hover {
		border: 1px solid $mscp-blue;
	}

	.sd-layer .sd-content .btn-link {
		padding: 10px 24px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		border: 1px solid #99cbe0;
		cursor: pointer;
		margin: 0 auto;
		display: inline-block;
		text-decoration: none;
		text-align: center;
		font-family: $font-reg;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: -0.4px;
	}

	.sd-layer .sd-content-footer {
		text-align: left;
		padding-top: 15px;
		padding-left: 15px;
		font-size: 11px;
		line-height: 1.45;
		color: #666;
		padding-right: 15px;
		font-family: "ArialMT", Arial, Helvetica, sans-serif;
	}

	.sd-content-footer a:hover {
		color: $mscp-blue;
		text-decoration: none !important;
	}
	/* End ad 3 */

	/* layer ad 3 */
	#layerAd3 .starts-in {
		padding-bottom: 5px;
	}

	.sd-layer .sd-header .logo {
		position: relative;
		margin-bottom: 20px;
	}

	.sd-layer .sd-header img {
    	height: 50px;
    }

	.sd-layer .sd-type {
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #666;
		font-size: 14px;
	}

	.sd-layer-background {
		z-index: 6000496;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-family: $font-reg;
		width: 570px;
		visibility: hidden;
	}

	.sd-layer .sd-title {
    	display: block;
    	font-size: 24px;
    	padding: 10px 20px;
    	font-weight: 600;
    	font-style: normal;
    	font-stretch: normal;
    	line-height: normal;
    	letter-spacing: normal;
    	text-align: center;
    	color: #222;
    	text-decoration: none;
    	display: inline-block;
	}

	.sd-layer .sd-info {
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #222;
		padding-bottom: 20px;
		margin: 0px;
		font-size: 20px;
	}

	.sd-layer {
		display: block;
		margin: 0 auto;
		min-height: 200px;
		background: $white;
		-moz-box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
		-webkit-box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
		box-shadow: 0px 4px 10px 0px rgba(000, 000, 000, 0.4);
		color: #000;
		padding-bottom: 30px;
	}

	.sd-layer .sd-header {
		border-bottom: 1px solid #d8d8d8;
		padding: 20px 20px 30px 20px;
	}

	.sd-layer .sd-header .logo {
		float: left;
		height: 16px;
	}

	.sd-layer .sd-header .close {
		margin: 0px 0 0 0;
		cursor: pointer;
		float: right;
		font-size: 14px !important;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #1b88bf;
		text-decoration: none;
		text-transform: uppercase;
	}

	.sd-layer .sd-header .close a {
		text-decoration: none;
	}

	.sd-layer .sd-content {
		padding: 30px 34px 0 34px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
	}

	#layerAd3Wrap .btn {
		padding: 10px 24px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		border: 1px solid #99cbe0;
		cursor: pointer;
		margin: 0 auto;
		display: inline-block;
		text-decoration: none;
		text-align: center;
		font-family: $font-reg;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: -0.4px;
	}

	.sd-content .cta {
		text-align: center;
	}

	.sd-content .sd-jc {
		margin-top: 15px;
		text-align: right;
		font-size: 11px;
		line-height: 1.45;
		color: #666;
		padding-right: 15px;
		font-family: "ArialMT", Arial, Helvetica, sans-serif;
	}

	.selective-driver .references {
		text-align: left;
		padding-left: 15px;
		padding-bottom: 15px
	}

	a:active.sd-pi, 
	a.sd-pi, 
	a:visited.sd-pi, 
	a:link.sd-pi {
		color: #666;
		text-decoration: none !important;
	}

	a:hover.sd-pi {
		color: $mscp-blue;
		text-decoration: none !important;
	}
}
/* End ad 3 */

@include breakpoint(1) {

	.sd-layer-background {
    	background-color: rgba(255, 255, 255, 0.75);
    	width: 100vw;
    	position: fixed;
    	z-index: 6000496;
    	margin: 0 auto;
    	min-height: 100%;
    	height: auto;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	font-family: $font-reg;
    	font-size: 14px;
    	text-align: center;
    	color: #222;
		visibility: hidden;
		padding: 0;
		margin: 0;
	}

	.sd-layer-show {
		overflow: hidden;
	}

	.sd-layer-show .sd-layer-background {
		visibility: visible;
	}

	.sd-layer {
		position: absolute;
		bottom: 0;
		background: white;
		width: 100vw;
		display: flex;
		flex-direction: column;
		box-shadow: 0px -10px 20px 0px #eee;
    	padding-left: 0px;
		padding-right: 0px;
		height: 0;
		transition: all .75s ease
	}

	.sd-layer {
		position: absolute;
		background: white;
		background: -webkit-linear-gradient(bottom left, white, white, #E3EFF8);
		background: -o-linear-gradient(bottom left, white, white, #E3EFF8);
		background: -moz-linear-gradient(bottom left, white, white, #E3EFF8);
		background: linear-gradient(to bottom left, white, white, #E3EFF8);
		width: 100vw;
		display: flex;
		flex-direction: column;
		box-shadow: 0px -10px -10px 0px #000;
		padding-left: 0px;
		padding-right: 0px;
		height: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transition: all .75s ease
	}

	.sd-layer-show .sd-layer {
		max-height: 60vh;
		height: 60vh;
		max-width: 100vw;
		margin: auto auto;
		left: 0;
		right: 0
	}

	@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {

		.sd-layer-show .sd-layer {
			max-height: 80vh;
			height: 80vh;
			max-width: 90vw;
			margin: auto auto;
			left: 0;
			right: 0;
		}

		.sd-layer {
			max-width: 320px;
			margin: auto auto;
			left: 0;
			right: 0;
		}
	}

	.sd-layer .sd-header {
		padding: 12px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: #e1e1e1 1px solid;
	}

	.sd-layer .sd-content-wrap {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	.sd-layer .sd-content {
		padding-top: 20px;
		padding-bottom: 0;
		padding-left: 10px;
		padding-right: 10px;
	}

	.sd-layer .sd-content .sd-type {
		font-size: 12px;
		color: #666;
		margin-bottom: 15px;
	}

	.sd-layer .sd-content .sd-title {
		font-size: 18px;
		color: #222;
		margin-bottom: 5px;
		font-family: $font-semi;
		text-align: center;
	}

	.sd-layer .sd-content .sd-info {
		margin-bottom: 24px;
	}

	.sd-layer .sd-content .btn-link {
		font-size: 18px;
		padding-top: 9px;
		padding-bottom: 9px;
		letter-spacing: -0.4px;
		border-radius: 3px;
		color: $white;
		background: $mscp-blue;
		margin: auto auto;
		text-decoration: none;
		display: inline-block;
		width: 200px;
		text-align: center;
	}

	.sd-layer .sd-content .btn-link:hover {
		background: $hover-blue;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
		transition: 0.5s;
	}

	.sd-layer .sd-content-footer {
    	padding-top: 15px;
    	padding-bottom: 25px;
    	padding-left: 20px;
    	padding-right: 20px;
    	margin-bottom: 20px;
    	box-sizing: border-box;
    	font-size: 10px;
    	line-height: 12px;
    	color: #666;
    	text-align: left;
	}

	.sd-layer .sd-content-footer .sd-jc {
		text-align: right;
	}

	.sd-layer .sd-content-footer p {
		margin-bottom: 10px;
		margin-top: 0;
    	margin-left: 0;
    	margin-right: 0;
    	padding: 0;
	}

	.sd-layer .sd-content-footer.is-empty {
		padding-top: 0;
		padding-bottom: 50px;
		box-sizing: border-box;
	}

	.sd-layer .sd-content-footer a {
		text-decoration: none;
		color: #1b88bf;
	}

	.sd-layer .sd-content-footer p {
		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	.sd-layer .sd-header .logo img {
    	float: left;
    	height: 16px;
    	width: auto;
	}

	.sd-layer .sd-header .close {
    	float: right;
    	text-transform: uppercase;
    	color: #1b88bf;
    	text-decoration: none;
	}

	.sd-layer .sd-header .close a,
	.sd-layer .sd-header .close a:hover {
    	text-decoration: none;
	}

	.selective-driver-cdtp .sd-layer .sd-content .sd-title {
    	font-family: $font-reg;
    	text-align: center;
    	font-size: 22px;
    	color: #222;
    	line-height: 24px;
    	margin-bottom: 15px;
    	text-align: center;
	}

	.selective-driver-cdtp .sd-content p.starts-in {
    	font-size: 18px;
    	text-align: center;
    	color: #222;
    	margin: auto auto;
    	margin-bottom: 5px;
    	padding: 0;
	}

	.selective-driver-cdtp .sd-layer .sd-content .image {
    	width: 230px;
    	margin: auto auto;
    	margin-bottom: 35px;
	}

	.selective-driver-cdtp .sd-layer .sd-content .image img {
    	height: 50px;
    	text-align: center;
    	display: inline;
	}

	.selective-driver-cdtp .sd-layer .sd-content #counter {
    	margin-top: -43px;
    	font-size: 31px;
    	font-weight: 700;
    	background: none;
    	padding: 0;
    	color: black;
	}
}
/* end selective-driver layer */