// FAF - First Article Free - site gating

.faf {
	padding: 17px 30px 0;
	background: $white;
	width: 100%;
	max-height: 600px;
	min-height: 172px;
	overflow-y: auto;
	position: sticky; 
	bottom: 0;
	left: 0;
	color: $light-black;
	box-shadow: 0 0 50px 50px rgba(255,255,255,1);
	box-sizing: border-box;
	z-index: $z-index_layer + 503;

	@include breakpoint(3) {
		padding: 16px 16px 0;
	}

	@include breakpoint(1) {
		max-height: 100vh;
		min-height: 222px;
		padding: 18px 18px 0;
	}

	&.active {
		min-height: 396px;
		position: fixed;

		@include breakpoint(1) {
			min-height: 456px;
		}
	}

	&-content {
		margin: 26px auto 0;
		padding-bottom: 26px;
		max-width: 96%;
    	width: 1240px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include breakpoint(3) {
			width: auto;
		}

		@include breakpoint(1) {
			margin: 20px 15px 0;
			padding-bottom: 24px;
			flex-direction: column;
			text-align: center;
		}

		.active & {
			margin: 70px 34px 0 34px;
			display: none;
			text-align: center;
		}
	}

	&-border {
		display: none;
	}

	&-toggle {
		margin: 0 auto;
		width: 22px;
		height: 13px;
		background: url("/pi/global/icons/icon-chevron-up.svg") no-repeat;
		background-size: 22px auto;
		cursor: pointer;
		position: absolute;
		left: 0;
		right: calc(0% - 1230px);
		z-index: $z-index_layer + 504;
		display: none;

		@include breakpoint(3) {
			margin: 0;
			left: auto;
			right: 30px;
		}

		@include breakpoint(1) {
			right: 18px;
			z-index: 1;
		}

		.active & {
			background: url("/pi/global/icons/icon-chevron-down.svg") no-repeat;
			background-size: 22px auto;
			display: block;
		}
	}

	&-cta {
		margin: 0 0 0 55px;
		flex: 0 0 300px;
		text-align: center;

		@include breakpoint(2) {
			flex: 0 0 220px;
		}

		@include breakpoint(1) {
			margin: 10px 0 0;
			flex: auto;
			min-width: 300px;
		}

		.active & {
			display: none;
		}
	}

	p {
		margin: 0 0 10px;
		padding: 0;

		&.callout {
			font-family: $font-bold;
			font-size: 1.75rem;
			line-height: 1.875rem;

			@include breakpoint(1) {
				font-size: 1.25rem;
				line-height: 1.563rem;
			}
		}

		&.description {
			font-size: 1rem;
			line-height: 1.125rem;
			opacity: 0.75;

			@include breakpoint(1) {
				line-height: 1.25rem;
			}
		}
	}

	a {

		&:hover {
			text-decoration: none;
		}

		&.faf-button {
			padding: 15px 15px 13px;
			font-family: $font-semi;
			font-size: 1.063rem;
			line-height: 1.25rem;
			color: $white;
			background: $mscp-blue;
			border-radius: 30px;
			width: 100%;
			cursor: pointer;
			display: block;

			&:hover {
				background: $hover-blue;
				@include hover-ease;
			}

			@include breakpoint(1) {
				padding: 9px 15px 7px;
				font-size: 1rem;
			}
		}
	}

	.logo-mscp {
		margin: 0 0 36px;
		background: url("/pi/logos/logo-medscape.svg") no-repeat;
		background-size: 140px auto;
		width: 140px;
		height: 33px;
		font-size: 0;
		display: none;
	}

	#fpfWidget, 
	#app {
		margin: 0 auto;
		max-width: 90vw;
		// text-align: center;
		display: none;

		@include breakpoint(2) {
			max-width: 86vw;
		}
		@include breakpoint(1) {
			margin-top: 25px;
			max-width: 96vw;
		}
	}

	&.active { // expanded states

		.logo-mscp {
			display: inline-block;

			@include breakpoint(1) {
				display: none;
			}
		}

		#fpfWidget, 
		#app {
			display: block;
		}
	}

	.mdscp-profile-img img {
		box-sizing: content-box;
	}
}

.layer-greyout {
	margin: 0 auto;
	width: 100%;
	position: fixed;
	height: calc(100% - 225px);
	background: linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%);
	z-index: $z-index_layer + 502;
	bottom: 0;
	transition: all .25s ease-in-out;
	display: none;

	@include breakpoint(1) {
		height: calc(100% - 125px);
	}

	&.active {
		display: block;
	}
}

.faf-enabled {
	overflow: hidden;
}

.mscp-origin.logged-out {
	display: initial;
}