@import 'legacy',
		'../mixins';

.footer-global {
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.footer-col a{
	display: block;
	margin-bottom: .875rem;
}

.footer-col a:last-of-type{
	margin-bottom: .0;
}

.footer-logo {
	width: 100%;
	text-align: left;
	margin-bottom: 1rem;
	@media (max-width: 767px) {
		margin-bottom: .5rem;
	}
}

.footer-logo-image {
	width: 127px;
	height: 28px;
}

.footer-social {
	flex: 1.6;
	@media (max-width: 767px){
		width: 100%;
		padding-bottom: 2rem;
	}
	a {
		margin-right: .5rem;
		margin-bottom: .5rem;
		display: inline-block;
	}
}

.footer-sections {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 3rem;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	@media (min-width: 768px){flex: 5;}
	@media (max-width: 1023px){
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
	}
}


.page-footer {
	border-top: solid 2px $mscp-blue;
	padding-top: 2.5rem;
	padding-bottom: 4rem;
    margin-top: 2rem;
}

.footer-sections .footer-col {
	padding: 0 1rem;
	border-left: solid 1px #dbdbdb;
	font-family: 'proxima_nova_ltlight', sans-serif;
	font-size: .875rem;
	min-width: 125px;

	@include breakpoint(2) {
		min-width: 9.75rem;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
	}

	@include breakpoint(1) {
		margin-bottom: 0;
		padding: 0 0 2rem;
		border-left: none;
		border-top: solid 1px #dbdbdb;
		width: 100%;
	}
}

.footer-col,
.footer-social {
	text-align: left;
}


.footer-social h4,
.footer-col h4,
.footer-social .footer-heading,
.footer-col .footer-heading {
	font-family: $font-rb;
	margin-top: 0;
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.875rem;

	@include breakpoint(1) {
		margin-top: 0.875rem;

	}
}

/* .footer-social h4 {
	margin-top: 0;
	font-weight: 700;
	font-size: .8125em;
} */

/* .footer-social a {
	overflow: hidden;
	height: 2rem;
	width: 2rem;
	border-radius: 3px;
	background: #14658e;
	margin-right: .625rem;
	display: inline-block;
} */

.page-footer a:link,
.page-footer a:visited,
.page-footer a:active {
	color: #222;
	text-decoration: none;
}

.page-footer a:hover {
	color: $hover-blue;
	text-decoration: none;
}

.footer_legal-text {
	font-family: $font-reg;
	width: 500px;
	margin-top: 3rem;
	margin-left: auto;
	margin-right: auto;
    font-size: .75rem;
    line-height: 1.3;
    text-align: center;
    color: #767674;
	padding-bottom: 2.5rem;
}


.footer-intl.resp-container {
	-webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-intl-links a {
	margin-left: 1.5rem;
	display: inline-block;
	font-family: 'proxima_nova_ltlight', sans-serif;
}

.footer-intl-social {
	margin-left: 3rem;
	display: inline-block;
}

.footer-intl-social a {
  margin-right: .5rem;
}

@media (max-width: 767px) {

	.footer-intl.resp-container {
		-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
		-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
		margin-top: -1rem;
	}

	.footer-intl-branding {
		-webkit-box-flex: 0;
	    -ms-flex: none;
	    flex: none;
		width: 100%;
		padding-bottom: 1.5rem;
		border-bottom: solid 1px #ddd;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	}

	.footer-intl-links {
		padding-top: 1.5rem;
	}

	.footer-intl-links a {
		margin-left: 0;
		display: block;
		margin-bottom: 1rem;
		font-size: 1rem;
	}

	.footer-intl-social {
		margin-left: 3rem;
		display: inline-block;
	}

	.footer-intl-social a {
		margin-right: 0;
		margin-left: .5rem;
	}

}

#adtagfooter {
	margin-top: 2rem;
}
