.ifi-widget {
	background-color: rgba(238, 238, 238, 0.38);
	padding: 20px 20px 25px 20px;
}
.ifi-widget-head {
	border-bottom: solid 3px #4a4a4a;
	color: #000;
	font-family: 'proxima_nova_rgbold', Arial, sans-serif;
	font-size: .875rem;
	padding-bottom: 12px;
	text-transform: uppercase;
}
.ifi-widget-cont-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
.ifi-widget-cont-list li {
	padding: 11px 0;
	border-top: 1px solid #d8d8d8;
}
.ifi-widget-cont-list li:first-child {
	border-top: none;
}
.ifi-widget-cont-list li a {
	color: #000;
	font-family: "proxima_nova_ltsemibold", Arial, sans-serif;
	font-size: 1.125rem;
}
.ifi-widget-cont-list li a:hover {
	text-decoration: underline;
}
.rel-links-card#ifi-widget-card {
	border: none;
	padding: 0;
}
.rel-links-card#ifi-widget-card li {
	padding: 11px 0;
}
.rel-links-card#ifi-widget-card li a {
	color: #000;
	font-family: "proxima_nova_ltsemibold", Arial, sans-serif;
	font-size: 1.125rem;
}

/* Conference OTG Unit
*************************************************/

/*reference override*/
#main2col #otg-unit.bucket {
  background: #fefdfd;
  border:0;
  border-top: 2px solid #3273a4;
  padding:5px 10px;
  border-bottom: 1px solid #aabed1;
  margin: 10px 0;
}

#otg-unit {
	margin: 20px 0 30px 0;
	padding: 10px 0;
	text-align: left;
	
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	
	h2 {
		font-family: "proxima_nova_ltsemibold", sans-serif;
		font-size: 1.375rem;
		color: #2f2148;
		font-weight: normal;
		line-height: 1;
		margin: 0 0 6px 0;
	}
	
	h4 {
    	margin: 0;
    	padding: 0 0 6px 0;
    	color: #888;
    	font-size: 0.8125em;
    	font-weight: normal;
    	line-height: 1;
	}
	
	.bucketContent {
		margin: 8px 0 0;
	}
	
	li {
		
		margin: 0;
		padding: 12px 0;
		border-top: 1px solid #d8d8d8;
		display: inline-block;
		width: 100%;
		
		a.title {
			margin: 0;
			font-family: "proxima_nova_rgregular", sans-serif;
			font-size: 0.9375rem;
			color: #2a2a2a;
			font-weight: normal;
			line-height: 1.2;
			display: block;
		}
		
		.byline, 
		.conftag {
			display: none;
		}
	}
	
	.viewMore {
		padding: 0 8px 0 0;
		font-family: 'proxima_nova_rgregular', arial, sans-serif;
		font-weight: normal;
		font-size: 0.875rem;
		background: transparent url("//img.medscape.com/pi/global/ornaments/icon-morelink-gt.png") no-repeat;
		background-position: right 0 top 6px;
		text-align: right;
		
		@media only screen and (-webkit-min-device-pixel-ratio:2), only screen and (min-resolution:192dpi) {
			background-image: url("/pi/global/ornaments/icon-morelink-gt-@2x.png");
      		background-size: 4px 7px;
		}
		
		img {
			display: none;
		}
		
		a {
			color: #064aa7;
		}
	}
}
