// PPE-321420 + PPE-321085
// temporarily hide all feedback functionality until updated functionality is enabled

.pers-body #feedback-button-container,
.slry-share-icon.email,
.mob-feedback-wrapper,
.cme-article-tools ul li a.email_func,
.decision-point .article-wrapper .dp-feedback-link,
.feedbackCallout2,
#content_qna .refknowledge-survey {
	display: none;
}

.nav-feedback-wrap {
	display: none !important;
}
