

.fpf_wrapper_div{
    width: 100%;
    margin-top: -30px;
    background-image: -webkit-linear-gradient(197deg, #d6e5e8, #dcd9e4);
    background-image: -o-linear-gradient(197deg, #d6e5e8, #dcd9e4);
    background-image: linear-gradient(287deg, #d6e5e8, #dcd9e4);
    border: solid 1px #dddddd;
    height:121px;
}


@media (max-width: 1024px) {
    .fpf_wrapper_div {
        height:150px;
    }
}

.fpf_marketing_widget {
    height: 300px;
    background-image: url("//img.medscape.com/pi/global/icons/fpf_marketing.png");
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    border: solid 1px #dddddd;
    width: auto;
    margin-top:1px;
}

.fpf_marketing_text {
  padding: 13% 10% 0 10%;
  height: 186px;
  font-family: "proxima_nova_rgregular",arial,sans-serif;;
  font-size: 1.3125rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
}

@media(min-width: 1190px){
  .fpf_marketing_text{
    padding-top:10%;
  }
}

@media (max-width: 767px) {
    .fpf_marketing_widget {
        display:none;
    }
}


.fpf_widget_title {
    text-align: center;
    font-family: "proxima_nova_rgregular",arial,sans-serif;
    font-size:1.2375rem;
    padding-top:10px;
}
.fpf_widget{
  max-width:690px;
  margin-left:auto;
  margin-right:auto;
}
.fpf_login{

width: 10%;
height: 5%;
border-radius: 3px;
background-color: #064aa7;
font-family: "proxima_nova_ltsemibold";
padding:5px;
margin: 0 5px;
}

.fpf_button_wrapper {
    margin: auto;
    width: 450px;
    height: 60px;
    padding: 10px;
    text-align:center;
}
@media(min-width: 1100px){
  .fpf_button_wrapper {width:100%;
  }
}

@media (max-width: 1024px) {
    .fpf_button_wrapper {
        width:200px;
        padding:5px;
    }
}


.fpf_button_div {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 15px;
}

.fpf_login_button:hover {
    cursor: pointer;
    background-color: $hover-blue;
}
.fpf_login_button {
    -webkit-appearance: none;
    font-family: "proxima_nova_ltsemibold",sans-serif;
    border: 0;
    border-radius: 2px;
    margin:0 2.5px;
    width: 45%;
    height: 35px;
    background-color: #064aa7;
    color: #fff;
    font-size: 0.93rem;
    transition: background-color .5s;
}

.fpf_fadeout_mobile{
  position:relative;
  margin-top:40px;
  background:-webkit-linear-gradient(rgba(255,255,255,0) 0%, #fff 100%);
  background-image:-moz-linear-gradient(rgba(255,255,255,0) 0%, #fff 100%);
  background-image:-o-linear-gradient(rgba(255,255,255,0) 0%, #fff 100%);
  background-image:linear-gradient(rgba(255,255,255,0) 0%, #fff 100%);
  background-image:-ms-linear-gradient(rgba(255,255,255,0) 0%, #fff 100%);
}

@media (max-width: 1024px) {
    .fpf_login_button {
        display:block;
        padding:0px;
        width:200px;
        margin:0px;
    }
}
@media (max-width: 1024px) {
    .fpf_text_or {
        padding:2px 50px;
    }
}

@media(min-width:1200px){
  .fpf_text_or{
    padding:0 2px;
  }
}
.fpf_fadeout_video {
    position: relative;
    margin-top:60px;
}

.fpf_fadeout {
    position: relative;
    bottom: 4em;
    height: 4.5em;
    margin-top:-20px;
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}

@media (max-width: 1024px) {
    .fpf_fadeout_float {
        bottom: 4em;
        height: 4.5em;
    }
}

.fpf_fadeout_float {
    position: relative;
    bottom: 3.5em;
    height: 8.5em;
    margin-top:-20px;
    background: -webkit-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -o-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    background-image: -ms-linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}
